#error {
    height: 100vh;
    width: 100%;
    background-color: #e9ecef;
}
.error-title {
    font-size: 10rem;
    font-weight: 500;
    display: block;
    line-height: 1.2;
    color: #475f7b;
    font-family: Raleway;
    margin-bottom: .5rem;
    margin: 0 !important;
    padding: 0 !important;
    /* border: 1px solid red; */
}
.pt-32 {
    padding-top: 6rem;
}
.text-center {
    text-align: center!important;
}
#error .error-title {
    font-size: 10rem;
}
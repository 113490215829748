.product-status{
    border-radius: 8px !important;
    width: 70px;
    text-align: center;
}

.paystack-button {
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  float: right;
  background: #65D6C9;
  padding: 10px;
  margin-left: 10px;
}

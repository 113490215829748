.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.cover-responsive {
    object-fit: cover;
    width: 100%;
    height: 70px;
}
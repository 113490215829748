.stretch-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
  }
  
  
  .bg-gradient-danger {
    background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096)) !important;
    background: linear-gradient(to right, #ffbf96, #fe7096) !important;
  }
  .bg-gradient-info {
    background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf)) !important;
    background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
  }
  .bg-gradient-success {
    background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
    background: linear-gradient(to right, #84d9d2, #07cdae) !important;
  }
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.3125rem;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .card-body{
    padding: 30px;
  }
  .card-img-absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}
  .card-title{
    color: #fff;
    font-size: 18px;
  }
  .card-content{
    color: #fff;
    font-size: 28px;
    font-weight: bold;
  }
  
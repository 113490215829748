.tranType{
    border-radius: 8px !important;
    width: 50px;
    text-align: center;
}

.TT-responsive-card {
    font-size: 10px;
    padding: 10px 20px;
    background-color: #fff;
}

.TT-responsive-card:first-of-type {
    margin-top: -20px;
}

.responsive-view-row .ant-col:nth-child(2) {
    text-align: right;
}
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.box {
    z-index: 9;
    width: 100%;
    height: 90%;
}
.spin {
    height: 100%;
    display: flex;
}


/* BASIC */

.h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
}

.forgot-password{
  text-align: center;
  display:inline-block;
  color: #0d0d0d;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  padding: 20px;
  background-color: #6772E5;
  position: relative;
}

#formContent {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 100%;
  height: 100%;
  max-height: 570px;
  max-width: 450px;
  position: absolute;
  padding: 0px;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
  overflow-y: scroll;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}



/* TABS */

.h2.inactive {
  color: #cccccc;
}

.h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}



/* FORM TYPOGRAPHY*/

.form input[type=button], .form input[type=submit], .form input[type=reset]  {
  background-color: #6772E5;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  transition: all 0.3s ease-in-out;
}

.form input[type=submit]:hover{
  background-color: #39ace7;
}

.form input[type=button]:active, .form input[type=submit]:active, .form input[type=reset]:active  {

}

.form input[type=text], .form input[type=number], .form input[type=password], .form input[type=email] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

.form input[type=text]:focus, .form input[type=password]:focus, .form input[type=email]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

.form input[type=text]:placeholder, .form input[type=password]:placeholder, .form input[type=email]:placeholder {
  color: #cccccc;
}

.showPassword{
  position: absolute !important;
  top: 25px !important; 
  right: 50px !important; 
  color: #58BAED !important;
}


/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #6772E5;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}

* {
  box-sizing: border-box;
}
.logo{
  height: auto;
  margin: 10px;
  padding: 10px;
}

.logo img{
  height: 100%;
  width: 100%;
}

.nav-profile-image {
  background: #1890ff;
  width: 50px;
  height: 50px;
  margin: auto;
  padding-top: 10px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.ant-input{
  background: transparent !important;
}

.site-layout-background .header-dropdown{
  margin-right: 7vh;
}

.site-layout .site-layout-background {
  background: #F2EDF3;
}

.side-bar-shadow{
  box-shadow: 0 1px 0 -3px rgba(0, 0, 0, 0.2);
}

.ant-table table { 
  font-size: 12px;
}

.transferContainer{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: scroll;
    background: #fff;
}
.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 997;
    display: block;
}
.ant-table table { font-size: 11px; }

.stretch-card {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
  
  
  .bg-gradient-danger {
    background: linear-gradient(to right, #ffbf96, #fe7096) !important;
  }
  .bg-gradient-info {
    background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
  }
  .bg-gradient-success {
    background: linear-gradient(to right, #84d9d2, #07cdae) !important;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.3125rem;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .card-body{
    padding: 30px;
  }
  .card-img-absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}
  .card-title{
    color: #fff;
    font-size: 18px;
  }
  .card-content{
    color: #fff;
    font-size: 28px;
    font-weight: bold;
  }
  
.complete-registration-notification{
    background: #fff;
    height: auto;
    padding: 15px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
}
.tranType{
    border-radius: 8px !important;
    width: 50px;
    text-align: center;
}

.TT-responsive-card {
    font-size: 10px;
    padding: 10px 20px;
    background-color: #fff;
}

.TT-responsive-card:first-of-type {
    margin-top: -20px;
}

.responsive-view-row .ant-col:nth-child(2) {
    text-align: right;
}
.account-number{
    width: 120px;
    background: rgb(104, 186, 253);
    color: #fff !important;
    padding: 3px;
    border-radius: 8px;
}
.product-status{
    border-radius: 8px !important;
    width: 70px;
    text-align: center;
}

.paystack-button {
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  float: right;
  background: #65D6C9;
  padding: 10px;
  margin-left: 10px;
}

.status{
    border-radius: 8px !important;
    width: 70px;
    text-align: center;
}
.deliveryStatus{
    border-radius: 8px !important;
    width: 85px;
    text-align: center;
    cursor: pointer;
}

.reference{
    cursor: pointer;
    color: #ff4d4f;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  padding: 20px;
  background-color: #6772E5;
}

.formContent {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 100%;
  height: 100%;
  max-height: 570px;
  max-width: 450px;
  position: absolute;
  padding: 0px;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.formContent .h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 0 20px; 
  color: #0d0d0d;
}

/* TABS */


/* FORM TYPOGRAPHY*/

.form input[type=button], .form input[type=submit], .form input[type=reset]  {
  background-color: #6772E5;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  transition: all 0.3s ease-in-out;
}

.form input[type=submit]:hover{
  background-color: #39ace7;
}

.form input[type=button]:active, .form input[type=submit]:active, .form input[type=reset]:active  {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.form input[type=text], .form input[type=number], .form input[type=password], .form input[type=email] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

.form input[type=text]:focus, .form input[type=password]:focus, .form input[type=email]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

.form input[type=text]:placeholder, .form input[type=password]:placeholder, .form input[type=email]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #6772E5;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}

* {
  box-sizing: border-box;
}

.back-to-home{
  float: left !important;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
}
#error {
    height: 100vh;
    width: 100%;
    background-color: #e9ecef;
}
.error-title {
    font-size: 10rem;
    font-weight: 500;
    display: block;
    line-height: 1.2;
    color: #475f7b;
    font-family: Raleway;
    margin-bottom: .5rem;
    margin: 0 !important;
    padding: 0 !important;
    /* border: 1px solid red; */
}
.pt-32 {
    padding-top: 6rem;
}
.text-center {
    text-align: center!important;
}
#error .error-title {
    font-size: 10rem;
}
.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.cover-responsive {
    object-fit: cover;
    width: 100%;
    height: 70px;
}

@import '~antd/dist/antd.css';

.box {
    z-index: 9;
    width: 100%;
    height: 90%;
}
.spin {
    height: 100%;
    display: flex;
}


@import '~antd/dist/antd.css';

.logo{
  height: auto;
  margin: 10px;
  padding: 10px;
}

.logo img{
  height: 100%;
  width: 100%;
}

.nav-profile-image {
  background: #1890ff;
  width: 50px;
  height: 50px;
  margin: auto;
  padding-top: 10px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.ant-input{
  background: transparent !important;
}

.site-layout-background .header-dropdown{
  margin-right: 7vh;
}

.site-layout .site-layout-background {
  background: #F2EDF3;
}

.side-bar-shadow{
  box-shadow: 0 1px 0 -3px rgba(0, 0, 0, 0.2);
}

.ant-table table { 
  font-size: 12px;
}

.transferContainer{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: scroll;
    background: #fff;
}
.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 997;
    display: block;
}